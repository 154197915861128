import axios from "axios";

class DATAMART_CONFIG_API {
  accessToken;
  email;
  idtoken;
  redX;
  configUrl;
  constructor() {
    //this.interceptRequests();
  }

  /** Init config */
  init({ accessToken, email, idtoken, redX }) {
    if (accessToken && email) {
      this.accessToken = accessToken;
      this.email = email;
      this.idtoken = idtoken;
      this.redX = redX;
      // this.configUrl = configUrl;
    } else {
      return { msg: "Failed to initiate." };
    }
  }

  /** saveCartDetails */
  saveCartDetails = async (cartDataList) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/save_to_cart${params}`,
      data: cartDataList
    });
    return res.data
  }


  getUserRole = async (emailId) => {
    let params = `?username=${emailId}`;
    const res = await axios({
      method: "get",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/getUserRole${params}`
    });
    return res.data
  }
  
  /** fetchOffer */
  fetchOffer = async (dasboardParam) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/dashboard${params}`,
      data: dasboardParam
    });
    return res.data?.data ? res.data?.data : res.data
  }

  /** handleBookmark */
  handleBookmark = async (offer_id,action_type) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/dashboard/bookmark${params}`,
      data: {offer_id,action_type}
    });
    return res.data?.data ? res.data?.data : res.data
  }

}
const DATAMART_API = new DATAMART_CONFIG_API();
export default DATAMART_API;