import { createSlice } from "@reduxjs/toolkit";
import { setDataByKey } from "Components/Logics/ensoCommonFunctions";
// import _ from "lodash";

const initialData = {
  stepper_data: [
    { name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business' , stepname: 'business'},
  ],
  active_step: "business",
  active_substep:"",
  solution_selected : "",
  solutionCategory : "",
  solutionType:"",
  solution_form_data : [],
  ndac_services: {},
  trainingServiceQty : 0,
  fieldsInformation : {
    "devices" : {
      "grouping" : ['skip_network_location_band_filter','connectivity_devices','bands','duplex_mode'],
      "expand_panel" : true,
      "radio_expand_panel" : false,
      "wrapAfter": ['skip_network_location_band_filter']
    },
    "connectivity" :{
      "grouping" :  ['skip_current_step_connectivity','skip_network_location_band_filter_connectivity','deployment_type','connectivity_services','connectivity_bands','capacity','connectivity_technology','compact_kit_type','is_this_1st_ndac_deployment_by_partner'],
      "expand_panel" : true,
      "radio_expand_panel" : false,
      "wrapAfter": ['skip_network_location_band_filter_connectivity'],
      "miningSearchGroup1": ['technology_das','ibwave_input_available','donwlink_frequency_mhz'],
      "miningSearchGroup2": ['technology_das','donwlink_frequency_mhz','ibwave_input_available_5g']
    },
    "applications" :{
      "grouping" :  [],
      "expand_panel" : false,
      "radio_expand_panel" : false
    },
    "mxie" :{
      "grouping" :  [],
      "expand_panel" : false,
      "radio_expand_panel" : true
    },
    "services" :{
      "grouping" :  [],
      "expand_panel" : false,
      "radio_expand_panel" : false
    },
    "sla_warranty" :{
      "grouping" :  [],
      "expand_panel" : false,
      "radio_expand_panel" : false
    }
  },
  serviceData: {},
  isStepperLoading:false,
  isSegmentListLoading:false,
}

const config_stepperSlice = createSlice({
  name: "caseDetails",
  initialState: {
    data: initialData
  },
  reducers: {
    ...setDataByKey,

    setDataConfig(state, action) {
      if((action.payload).length > 1) {
        (action.payload).forEach(payload => {
          state.data = {
            ...state.data,
            [payload.key]: payload.value
          };
        });
      } else {
        state.data = {
          ...state.data,
          [action.payload.key]: action.payload.value,
        };
      }
    },
    setServiceData(state,action) { 
      state.data.serviceData = {
        ...state.data.serviceData,
        [action.payload.key]: action.payload.value,
      };
    },
    clearDataConfig(state,){
      state.data = { ...initialData };
    },
    setNdacServices(state, action){
      state.data.ndac_services = action.payload
    },

  }
});

export default config_stepperSlice.reducer;
export const {
  setDataConfig, clearDataConfig, setNdacServices,setServiceData
} = config_stepperSlice.actions;
