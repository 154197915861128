import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tabs } from "@nokia-csf-uxr/ccfk";
import { Tab } from "@nokia-csf-uxr/ccfk/Tabs";

import LayoutGridIcon from "@nokia-csf-uxr/ccfk-assets/latest/LayoutGridIcon";
import EditIcon from "@nokia-csf-uxr/ccfk-assets/latest/EditIcon";
import ListIcon from "@nokia-csf-uxr/ccfk-assets/latest/ListIcon";
import DeleteIcon from "@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon";

import { FILTER_TOOLBAR_TABS, OFFER_STTATUS } from "../utils/consstants";
import { RootState } from "Store/mainStore";
import { setProperty } from "../store/dashboardSlice";
import MoreActionButtons from "Components/common/MoreActionButtons/MoreActionButtons";
import DATAMART_API from "Constants/services/datamart";
import { setError } from "Store/general/auth";

/*
 * Layout tabs for the dashboard.
 */
const LAYOUT_TAB = [
  {
    id: "grid",
    name: "Grid View",
    icon: <LayoutGridIcon />,
  },
  {
    id: "list",
    name: "List View",
    icon: <ListIcon />,
  },
];

/**
 * Get the status color based on the status
 * @param {string} status
 * @returns {string} color
 */
export const getStatusColor = (status) => {
  const statusItem = OFFER_STTATUS.find((item) => item.id === status);
  if (statusItem) {
    return statusItem.color;
  }
  return "var(--color-status-fault)";
};

/**
 * Get the status label based on the status
 * @param {string} status
 * @returns {string} status label
 */
export const getStatusLabel = (status) => {
  const statusItem = OFFER_STTATUS.find((item) => item.id === status);
  if (statusItem) {
    return statusItem.name;
  }
  return "Unknown";
};

/**
 * Custom hook that provides functionality for the dashboard component.
 *
 * This hook includes:
 * - Selectors to access the dashboard state.
 * - Dispatch to update the state.
 * - State management for the selected layout tab.
 * - Handlers for tab click events and filter tab changes.
 * - Functions to generate filter tabs and display layout tabs.
 *
 * @returns {Object} An object containing two functions:
 * - `getFilterTabs`: Generates the filter tabs for the dashboard.
 * - `getDisplayLayoutTab`: Generates the display layout tabs for the dashboard.
 */

const useDashboard = () => {
  // Selectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  // Hooks
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(LAYOUT_TAB[0].id);
  const clickHandler = (index) => () => {
    setSelected(index);
  };

  /**
   * More actions for the offer.
   * */
  const moreActions = [
    {
      item_label: "Edit case",
      item_key: "edit_case",
      disabled: false,
      icon: <EditIcon size="16px" />,
    },
    {
      item_label: "Edit properties",
      item_key: "edit_properties",
      disabled: false,
      icon: <ListIcon size="16px" />,
    },
    {
      item_label: "Delete",
      item_key: "delete",
      disabled: false,
      icon: <DeleteIcon size="16px" />,
    },
  ];

  /**
   * Handles the actions for the offer.
   *
   * @param {Object} item - The action item.
   * @param {Object} offer - The selected offer.
   * @returns {void}
   * @example
   * handleOfferActions(item, offer);
   * */
  const handleOfferActions = (item, offer) => {
    dispatch(
      setProperty({
        key: "caseActions",
        value: { action: item.item_key, selectedOffer: offer },
      })
    );
  };

  /**
   * Handles the filter tab change.
   *
   * @param {string} tabId - The selected tab id.
   * @returns {void}
   * @example
   * handleFilterTbChange(tabId);
   * */
  const handleFilterTbChange = (tabId) => {
    dispatch(setProperty({ key: "filterTab", value: tabId }));
  };

  /**
   * Handles the display layout change.
   *
   * @param {string} tabId - The selected tab id.
   * @returns {void}
   * @example
   * handleDisplayChange(tabId);
   * */
  const handleDisplayChange = (tabId) => () => {
    dispatch(setProperty({ key: "viewType", value: tabId }));
  };

  /**
   * Generates the filter tabs for the dashboard.
   *
   * @returns {JSX.Element} A JSX element containing the filter tabs.
   */
  const getFilterTabs = () => {
    return (
      <Tabs alignment="left" fullWidth={true}>
        {FILTER_TOOLBAR_TABS.map((tab) => {
          return (
            <Tab
              id={`tab-${tab.id}`}
              role="option"
              key={`tab-${tab.id}`}
              selected={dashboardSelector.data.filterTab === tab.id}
              onSelect={() => handleFilterTbChange(tab.id)}
            >
              {tab.name}
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  /**
   * Generates the layout tabs for the dashboard display.
   *
   * @returns {JSX.Element} A JSX element containing the layout tabs with icons.
   */
  const getDisplayLayoutTab = () => {
    return (
      <Tabs
        alignment="center"
        fullWidth={false}
        variant="icons"
        className="dsh-ft-view-tabs"
        //style={{ border: "solid 1px", justifyContent: "center",alignItems: "center" }}
      >
        {LAYOUT_TAB.map((tab) => {
          return (
            <Tab
              icon={true}
              tooltipProps={{
                tooltip: tab.name,
                placement: "bottom",
              }}
              id={`tab-${tab.id}`}
              role="option"
              key={`tab-${tab.id}`}
              selected={dashboardSelector.data?.["viewType"] === tab.id}
              onSelect={handleDisplayChange(tab.id)}
            >
              {/** @ts-ignore */}
              {tab.icon}
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  /**
   * Generates the formatted grid data for the dashboard.
   *
   * @param {Array} data - The data to be displayed in the grid.
   * @param {boolean} isInlineRowActions - A flag to determine if inline row actions are enabled.
   * @returns {Object} An object containing the formatted grid data.
   */
  const getFormatedGridData = (data, isInlineRowActions) => {
    const gridFormatData = {
      label: "",
      id_key: "case_list",
      // isInlineRowActions: isInlineRowActions,
      overlayNoRowsTemplate: "No data available for the current selection",
      rowData: data,
      columnDefs: [
        {
          headerName: "Status",
          field: "offerStatus",
          flex: 1.5,
          cellRendererFramework: (params) => {
            return getStatusLabel(params.value);
          },
          cellStyle: (param) => {
            return {
              borderLeft:
                "solid var(--g-data-grid-kpi-status-bar-width) !important",
              borderLeftColor: `${getStatusColor(param.value)} !important`,
            };
          },
        },
        { headerName: "Offer ID", field: "offer_id", flex: 3 },
        { headerName: "sCRM ID", field: "sCRMID", flex: 1.5 },
        { headerName: "Created Date", field: "createdAt", flex: 2 },
        { headerName: "Case name", field: "caseName", flex: 3 },
        { headerName: "Account", field: "account_name", flex: 2 },
        { headerName: "Owner", field: "owner", flex: 2.5 },
        { headerName: "Price", field: "totalPrice", flex: 1.5 },
        { headerName: "Duration", field: "offerAgeInDays", flex: 1.5 },
      ],
    };

    // Handle inline row actions
    if (isInlineRowActions) {
      const actionColoumn = {
        headerName: "",
        field: "",
        flex: 1,
        pinned: "right",
        cellRendererFramework: (params) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MoreActionButtons
                layout="horizontal"
                menuItems={moreActions}
                onMenuClick={(item) => handleOfferActions(item, params.data)}
              />
            </div>
          );
        },
      };
      //@ts-ignore
      gridFormatData["columnDefs"].push(actionColoumn);
    }
    return gridFormatData;
  };

  const processDraftState = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("Draft processed");
      }, 3000);
    });
  }

  const handleDeleteOffer = () => {
  }


  /**
   * Handles the bookmarking of an offer by calling the DATAMART_API.
   *
   * @param {string} offerId - The ID of the offer to be bookmarked.
   * @param {string} actionType - The type of action to perform (e.g., "add" or "remove").
   * @returns {Promise<any>} - A promise that resolves to the bookmark data.
   * @throws Will dispatch an error action if the bookmarking process fails.
   */
  const handleOfferBookmark = async (offer_id: string|number, actionType: string) => {
    try {
      const bookmark = await DATAMART_API.handleBookmark(offer_id, actionType);
      return bookmark;
    } catch (e) {
      dispatch(
        setError({
          key: "warn",
          value: {
            errorCode: null,
            errorMsg: "Something went wrong while updating offer bookmark",
          },
        })
      );
    }
  }

  return {
    moreActions,
    handleOfferActions,
    getFilterTabs,
    getDisplayLayoutTab,
    getFormatedGridData,
    getStatusColor,
    processDraftState,
    handleDeleteOffer,
    handleOfferBookmark,
  };
};

export default useDashboard;
